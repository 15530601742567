/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://eqnrvfzhkjhqzixa4azfirr55u.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2pd7splgpfdbpengp3klzyrzya",
    "aws_cognito_identity_pool_id": "eu-central-1:7cc6584a-b6fc-4e94-a208-f684eedb4e18",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_9NgQTmCO5",
    "aws_user_pools_web_client_id": "3jcu0p7ccn1b1vl0thl8ebkgj",
    "oauth": {
        "domain": "xjh98o38jlzw-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "https://kvit.pub/,http://localhost:3000/",
        "redirectSignOut": "https://kvit.pub/,http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "test-20230901072756-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://d2o22kl3kn4g82.cloudfront.net"
};


export default awsmobile;
